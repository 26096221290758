<template>
  <div v-if="true">
    <molecules-custom-dialog
      v-if="showSuspend"
      :show="showSuspend"
      title="Anda akan meng-suspend pekerja!"
      :caption="`${$toCapitalize(showSuspend.fullName)} akan tersuspend dari perusahaan anda!`"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => (showSuspend = null),
        },
        {
          title: 'Ya, Lanjutkan',
          override: 'red--text',
          full: false,
          exec: () => {
            // rest
            suspendWorker(reasonSuspend || 'Bermasalah')
          },
        },
      ]"
    >
      <v-text-field
        class="rounded-lg"
        placeholder="Alasan Suspend"
        append-icon="mdi-account-cancel"
        v-model="reasonSuspend"
        background-color="accent"
        hide-details
        solo
        flat
      />
    </molecules-custom-dialog>
    <molecules-custom-dialog
      :show="showApproveBulk"
      title="Apakah Anda Yakin?"
      :caption="`Anda akan approve semua absensi berdasarkan filter: ${
        ($store.state.attendance.data &&
          $store.state.attendance.data.filter((x) => x.locationId && x.absensiCompleted).length) ||
        0
      } (Absensi)!`"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => (showApproveBulk = false),
        },
        {
          title: 'Ya, Lanjutkan',
          override: 'red--text',
          full: false,
          exec: () => {
            approveBulk()
          },
        },
      ]"
    />
    <molecules-custom-dialog
      v-if="showApprove"
      :show="showApprove"
      :title="showApprove.supervisorApproval == $status.approve ? 'Approve Pekerja' : 'Reject Pekerja' + '?'"
      :caption="`Anda akan meng-${showApprove.supervisorApproval == $status.approve ? 'approve' : 'reject'} pekerja (${
        showApprove.worker && showApprove.worker.fullName
      })`"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => (showApprove = null),
        },
        {
          title: showApprove.supervisorApproval == $status.approve ? 'Approve' : 'Reject',
          override: 'red--text',
          full: false,
          exec: () => {
            toggleApproval()
          },
        },
      ]"
    >
      <atoms-text-field color="background" label="Komentar Anda" :noLabel="true" v-model="comment"
    /></molecules-custom-dialog>
    <!-- summary -->
    <v-row>
      <v-col cols="12" md="3">
        <molecules-summary-card
          icon="account-multiple"
          :title="`${$store.state.attendance.total} Pekerja Anda`"
          :desc="`${$moment($store.state.attendance.from || new Date()).format('DD MMMM YYYY')} - ${$moment(
            $store.state.attendance.till || new Date(),
          ).format('DD MMMM YYYY')}`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="3">
        <molecules-summary-card
          icon="account-details"
          :title="`${$store.state.attendance.length}
                  Absensi`"
          :cap="`Terhitung pada ${day === 'Semua' ? 'Semua Hari' : `hari ${day}`}`"
          :desc="`Periode ${$moment(from.value).format('DD MMMM YYYY')} - ${$moment(to.value).format('DD MMMM YYYY')}`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="3">
        <molecules-summary-card
          icon="account-check"
          :title="`${$store.state.attendance.complete}
                  Hadir`"
          :cap="`Terhitung pada ${day === 'Semua' ? 'Semua Hari' : `hari ${day}`}`"
          :desc="`Periode ${$moment(from.value).format('DD MMMM YYYY')} - ${$moment(to.value).format('DD MMMM YYYY')}`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="3">
        <molecules-summary-card
          icon="account-check"
          :title="`${$store.state.attendance.approved}
                  Approved`"
          :cap="`Terhitung pada ${day === 'Semua' ? 'Semua Hari' : `hari ${day}`}`"
          :desc="`Periode ${$moment(from.value).format('DD MMMM YYYY')} - ${$moment(to.value).format('DD MMMM YYYY')}`"
          color="green"
          :loading="reportLoading"
        />
      </v-col>
    </v-row>

    <v-form @submit.prevent="loadData">
      <v-row>
        <v-col cols="12" md="10">
          <atoms-text-field :noLabel="true" class="rounded-lg accent">
            <v-text-field
              v-model.trim="term"
              :appendAction="
                () => {
                  term = ''
                  loadData()
                }
              "
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
              placeholder="Ketikkan kata kunci pencarian..."
              appendIcon="mdi-close"
              class="rounded-lg ma-0 pa-0"
              hide-details
              flat
              solo
              dense
            >
              <template v-slot:prepend>
                <v-combobox
                  class="rounded-lg ma-0 pa-0"
                  placeholder="Query"
                  v-model="queryFilter"
                  :items="queryFilters"
                  item-text="title"
                  item-value="value"
                  hide-details
                  multiple
                  dense
                  solo
                  flat
                  required
                  outlined
                />
              </template>
            </v-text-field>
          </atoms-text-field>
        </v-col>
        <v-col cols="12" md="2" class="d-flex justifyx-space-between">
          <atoms-button type="submit" style="width: 100%" class="primary lighten-5 primary--text"
            ><v-icon left>mdi-magnify</v-icon>Cari Absensi</atoms-button
          >
        </v-col>
      </v-row>
    </v-form>
    <!-- main -->
    <v-row>
      <v-col cols="12" md="8" order="2" order-md="1">
        <v-row align="center">
          <v-col cols="12" md="auto" class="grow py-0 py-md-auto d-flex align-center">
            <atoms-text class="font-weight-medium"
              ><v-icon left>mdi-account-group</v-icon> (Periode) Absensi Pekerja</atoms-text
            ><v-icon
              @click="
                () => {
                  $showDialog({
                    title: 'Informasi Absensi',
                    body: 'Approval Absensi hanya bisa dilakukan jika sudah melalukan filtering dengan memilih Area dan Lokasi',
                  })
                }
              "
              right
              style="cursor: pointer"
              >mdi-information</v-icon
            >
          </v-col>
          <v-col cols="12" md="auto" class="shrink">
            <atoms-button
              :disabled="
                (areas.value && areas.value.title === $status.semua) ||
                (locations.value && locations.value.title === $status.semua) ||
                !$store.state.attendance.data
              "
              @click="showApproveBulk = true"
              style="width: 100%"
              class="primary lighten-5 primary--text"
              ><v-icon left>mdi-check</v-icon> Approve Semua Absensi</atoms-button
            >
          </v-col>
          <v-col cols="12" md="auto" class="shrink">
            <!-- v-if="$store.state.member.roles.includes($roles.Admin)" -->
            <atoms-button style="width: 100%" class="green lighten-4 green--text" @click="exportXlsx"
              ><v-icon left>mdi-google-spreadsheet</v-icon> Export Xlsx</atoms-button
            >
          </v-col>
          <v-col cols="12" md="auto" class="shrink">
            <atoms-button
              class="primary"
              style="width: 100%"
              @click="
                () => {
                  sort = sort === constant[0] ? constant[1] : constant[0]
                  getAttendance()
                }
              "
              ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
              >Sort: {{ sort }}</atoms-button
            >
          </v-col>
          <!-- <v-col cols="12" md="auto" class="shrink">
            <atoms-button
              style="width: 100%"
              class="green lighten-4 green--text"
              @click="exportXlsxByDays"
              ><v-icon left>mdi-google-spreadsheet</v-icon> Export Xlsx By
              Days</atoms-button
            >
          </v-col> -->
        </v-row>
        <v-fade-transition>
          <div v-if="mainLoading" class="mt-2">
            <v-skeleton-loader
              v-for="i in $store.state.attendance.limit || 5"
              :key="i"
              type="list-item-avatar-three-line"
              :class="['accent', 'rounded-lg pa-4 mb-2']"
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
            />
          </div>
          <div v-else class="mt-2">
            <div v-if="$store.state.attendance.data && $store.state.attendance.data.length > 0">
              <div
                v-for="(
                  { _id, ktpPhl, absensiCompleted, supervisorApproval, location, phlJamMulai, area, worker, ...rest }, i
                ) in $store.state.attendance.data.filter((x) => x.locationId && !x.worker.blacklist) || []"
                :key="i"
                :class="['accent', 'rounded-lg mb-2 overflow-hidden']"
                :style="{
                  'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                }"
              >
                <div class="pa-4 mb-2">
                  <v-row align="center">
                    <v-col class="shrink">
                      <v-avatar
                        :color="`${supervisorApproval ? 'primary' : absensiCompleted ? 'green' : 'red'} lighten-4`"
                        class="rounded-lg"
                      >
                        <atoms-image
                          v-if="worker && worker.photo"
                          class="rounded-lg"
                          :enableZoom="true"
                          :src="worker.photo"
                        />
                        <v-icon v-else :color="supervisorApproval ? 'primary' : absensiCompleted ? 'green' : 'red'"
                          >mdi-account</v-icon
                        ></v-avatar
                      >
                    </v-col>
                    <v-col cols="8" md="4">
                      <atoms-text class="font-weight-bold d-flex align-center"
                        >{{ $toCapitalize(worker.fullName || 'Lokasi') }}
                        <v-chip
                          v-if="
                            worker.blacklist ||
                            worker.resigned ||
                            (worker.clientSuspends &&
                              worker.clientSuspends.find((x) => x._id === $store.state.client._id))
                          "
                          small
                          class="rounded-lg red lighten-5 red--text ml-2"
                          >{{
                            (worker.blacklist && 'Blacklisted') ||
                            (worker.resigned && 'Resign') ||
                            (worker.clientSuspends &&
                              worker.clientSuspends.find((x) => x._id === $store.state.client._id) &&
                              'Suspend')
                          }}</v-chip
                        ></atoms-text
                      >
                      <atoms-text class="primary--text"
                        >Area {{ (area && area.title) || '-' }}, Lokasi
                        {{ (location && location.name) || '-' }}</atoms-text
                      >
                    </v-col>
                    <v-col align="start" cols="12" md="auto">
                      <atoms-text class="font-weight-bold"
                        >Status: {{ supervisorApproval ? supervisorApproval : 'Proses' }}</atoms-text
                      >
                      <atoms-text class="primary--text"
                        >Absensi <span v-if="rest.isManual">Manual</span> :
                        {{ absensiCompleted ? 'Lengkap' : 'Belum Lengkap' }}</atoms-text
                      >
                    </v-col>
                    <v-col title="Gaji Shift" :align="$vuetify.breakpoint.smAndDown ? 'start' : 'end'">
                      <atoms-text>Pukul: {{ $moment(phlJamMulai).format('H:mm:ss, DD MMMM YYYY') }}</atoms-text>
                      <atoms-text>{{ $idCurrency((rest.shift && rest.shift.priceBase) || 0) }}</atoms-text>
                    </v-col>
                    <v-col class="shrink">
                      <v-menu offset-x class="rounded-xl">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                        </template>
                        <v-list nav dense elevation="0" class="text-center rounded-lg">
                          <v-list-item
                            link
                            @click="
                              showApprove = {
                                _id,
                                ktpPhl,
                                absensiCompleted,
                                supervisorApproval: $status.approve,
                                location,
                                phlJamMulai,
                                area,
                                worker,
                                ...rest,
                              }
                            "
                            class="rounded-lg px-4 text-center"
                          >
                            <v-icon>mdi-account-check</v-icon>
                            <v-list-item-title class="px-2">Approve</v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            link
                            @click="
                              showApprove = {
                                _id,
                                ktpPhl,
                                absensiCompleted,
                                supervisorApproval: $status.reject,
                                location,
                                phlJamMulai,
                                area,
                                worker,
                                ...rest,
                              }
                            "
                            class="rounded-lg px-4 text-center"
                          >
                            <v-icon>mdi-account-cancel</v-icon>
                            <v-list-item-title class="px-2">Reject Absensi</v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            @click="
                              () => {
                                if (
                                  worker &&
                                  worker.clientSuspends &&
                                  Boolean(worker.clientSuspends.find((x) => x._id === client._id))
                                ) {
                                  unSuspendWorker(worker)
                                } else {
                                  showSuspend = {
                                    ...worker,
                                  }
                                }
                              }
                            "
                            class="rounded-lg px-4 text-center"
                          >
                            <v-icon left
                              >mdi-account-{{
                                worker &&
                                worker.clientSuspends &&
                                Boolean(worker.clientSuspends.find((x) => x._id === client._id))
                                  ? 'check'
                                  : 'cancel'
                              }}</v-icon
                            >
                            <v-list-item-title class="px-2">{{
                              worker &&
                              worker.clientSuspends &&
                              Boolean(worker.clientSuspends.find((x) => x._id === client._id))
                                ? 'Unsuspend'
                                : 'Suspend'
                            }}</v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            link
                            :to="`/attendance/riwayat?search=${ktpPhl}`"
                            class="rounded-lg px-4 text-center"
                          >
                            <v-icon left>mdi-account-details</v-icon>
                            <v-list-item-title class="px-2">Cek Riwayat</v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            link
                            class="rounded-lg px-4 text-center"
                            @click="
                              showDetail = {
                                _id,
                                ktpPhl,
                                absensiCompleted,
                                supervisorApproval,
                                location,
                                phlJamMulai,
                                area,
                                worker,
                                ...rest,
                              }
                            "
                          >
                            <v-icon left>mdi-information</v-icon>
                            <v-list-item-title class="px-2">Lihat Detail</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-container class="px-0">
                    <atoms-text class="font-weight-bold">{{
                      (rest.client && rest.client.name) || 'Client'
                    }}</atoms-text>
                  </v-container>
                </div>
                <div v-if="rest.isOutsideLocation && rest.geofenceDistance" :class="[' white--text pa-2 px-4 orange']">
                  <atoms-text>
                    Absensi dilakukan diluar lokasi gedung, Jarak absensi sejauh
                    {{ rest.geofenceDistance || 0 }}m dari gudang
                  </atoms-text>
                </div>
              </div>
              <v-row justify="center">
                <v-col cols="8">
                  <v-container class="max-width">
                    <v-pagination
                      class="my-4"
                      v-model="page"
                      :length="$store.state.attendance.pagesLength"
                    ></v-pagination>
                  </v-container>
                </v-col>
              </v-row>
            </div>
            <molecules-wrapper v-else class="d-flex justify-center align-center my-8 py-8 outlined">
              <div class="text-center">
                <atoms-image :width="250" src="/assets/missing.svg" />
                <atoms-title :h3="true" class="mt-6">Data Yang Anda Cari Tidak Ditemukan</atoms-title>
              </div>
            </molecules-wrapper>
          </div>
        </v-fade-transition>
      </v-col>
      <v-col cols="12" md="4" order="1" order-md="2">
        <atoms-text class="pa-2 font-weight-medium"><v-icon left>mdi-filter-variant</v-icon> Filter</atoms-text>
        <molecules-wrapper class="accent rounded-lg pb-8">
          <v-row>
            <!-- <v-col cols="12">
              <atoms-button
                @click="
                  () => {
                    getSummary();
                    getAttendance();
                  }
                "
                style="width: 100%"
                class="py-md-6 primary--text"
              >
                <v-icon left>mdi-magnify</v-icon> Cari Absensi
              </atoms-button>
            </v-col> -->
            <v-col cols="12">
              <atoms-text-field label="Status">
                <v-combobox
                  class="rounded-lg"
                  placeholder="Filters"
                  background-color="background"
                  prepend-inner-icon="mdi-filter-variant"
                  v-model="filter"
                  :items="filters.map((x) => x.title)"
                  hide-details
                  multiple
                  dense
                  solo
                  flat
                  required
                  :style="{
                    'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                  }"
                />
              </atoms-text-field>
            </v-col>
            <v-col cols="12">
              <v-dialog ref="pickerFromDialog" v-model="from.modal" :return-value.sync="from.value" width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <atoms-text-field label="Dari Tanggal">
                    <v-text-field
                      :loading="mainLoading"
                      class="rounded-lg"
                      placeholder="Dari Tanggal"
                      append-icon="mdi-calendar"
                      background-color="background"
                      v-model="formatFromDate"
                      v-bind="attrs"
                      hide-details
                      dense
                      v-on="on"
                      readonly
                      solo
                      flat
                    />
                  </atoms-text-field>
                </template>
                <v-date-picker
                  :max="$moment().endOf('month').format('YYYY-MM-DD')"
                  v-model="from.value"
                  scrollable
                  class="rounded-lg"
                  @click:date="$refs.pickerFromDialog.save(from.value)"
                />
              </v-dialog>
            </v-col>
            <v-col cols="12">
              <v-dialog ref="pickerToDialog" v-model="to.modal" :return-value.sync="to.value" width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <atoms-text-field label="Sampai Tanggal">
                    <v-text-field
                      class="rounded-lg"
                      placeholder="Sampai Tanggal"
                      append-icon="mdi-calendar"
                      background-color="background"
                      v-model="formatToDate"
                      :loading="mainLoading"
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                      readonly
                      dense
                      solo
                      flat
                    />
                  </atoms-text-field>
                </template>
                <v-date-picker
                  :max="$moment().endOf('month').format('YYYY-MM-DD')"
                  v-model="to.value"
                  scrollable
                  class="rounded-lg"
                  @click:date="$refs.pickerToDialog.save(to.value)"
                />
              </v-dialog>
            </v-col>
            <v-col cols="12">
              <atoms-text-field label="Hari">
                <v-autocomplete
                  class="rounded-lg"
                  :items="$days"
                  v-model="day"
                  :menu-props="{ offsetY: true }"
                  background-color="background"
                  hide-details
                  solo
                  dense
                  flat
                  required
                  :style="{
                    'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                  }"
                />
              </atoms-text-field>
            </v-col>
            <v-col cols="12" title="Ketik Nama Klien Lalu Enter">
              <atoms-text-field label="Klien">
                <v-autocomplete
                  class="rounded-lg"
                  :items="clients"
                  v-model="clientId"
                  :menu-props="{ offsetY: true }"
                  item-text="name"
                  item-value="_id"
                  background-color="background"
                  hide-details
                  solo
                  dense
                  flat
                  required
                  placeholder="Ketik Nama Klien Lalu Enter"
                  :style="{
                    'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                  }"
                  @keyup.enter="getClients"
                  :loading="clientLoading"
                />
              </atoms-text-field>
            </v-col>
            <v-col cols="12">
              <atoms-text-field label="Area">
                <v-autocomplete
                  class="rounded-lg"
                  :items="getAreaItems"
                  v-model="getAreaName"
                  :menu-props="{ offsetY: true }"
                  background-color="background"
                  hide-details
                  solo
                  dense
                  flat
                  required
                  :style="{
                    'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                  }"
                />
              </atoms-text-field>
            </v-col>
            <v-col cols="12">
              <atoms-text-field label="Lokasi">
                <v-autocomplete
                  class="rounded-lg"
                  :items="getAreaName === $status.semua ? [$status.semua] : getLocItems"
                  v-model="getLocName"
                  :menu-props="{ offsetY: true }"
                  background-color="background"
                  hide-details
                  solo
                  flat
                  dense
                  required
                  :style="{
                    'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                  }"
                />
              </atoms-text-field>
            </v-col>
            <v-col cols="12">
              <atoms-text-field label="Shift">
                <v-autocomplete
                  class="rounded-lg"
                  :items="shifts"
                  v-model="shiftId"
                  :menu-props="{ offsetY: true }"
                  background-color="background"
                  item-text="title"
                  item-value="_id"
                  hide-details
                  solo
                  flat
                  dense
                  required
                  :loading="shiftLoading"
                  :style="{
                    'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                  }"
                />
              </atoms-text-field>
            </v-col>
            <v-col cols="12">
              <atoms-text-field label="Referral">
                <v-autocomplete
                  class="rounded-lg"
                  :items="referrals"
                  v-model="referralId"
                  :menu-props="{ offsetY: true }"
                  background-color="background"
                  item-text="title"
                  item-value="_id"
                  hide-details
                  solo
                  dense
                  flat
                  required
                  placeholder="Pilih Referral"
                  :style="{
                    'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                  }"
                />
              </atoms-text-field>
            </v-col>
          </v-row>
        </molecules-wrapper>
      </v-col>
    </v-row>
    <v-slide-x-reverse-transition>
      <organism-attendance-detail
        v-if="showDetail"
        @closed="
          () => {
            showDetail = null
          }
        "
        @toggleApprove="(e) => (showApprove = e)"
        :data="showDetail"
      />
    </v-slide-x-reverse-transition>
  </div>
  <div v-else>
    <atoms-text
      >Akses Halaman di
      <a href="https://hayosystems-v3.web.app/attendance/overall"
        >https://hayosystems-v3.web.app/attendance/overall</a
      ></atoms-text
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      sort: '',
      constant: ['Desc', 'Asc'],
      showApproveBulk: false,
      showSuspend: null,
      reasonSuspend: '',
      mainLoading: false,
      reportLoading: false,
      day: this.$days[0],
      term: '',
      comment: 'OK',
      referrals: [],
      showDetail: null,
      showApprove: null,
      filter: null,
      referralId: '',
      filters: [
        {
          title: 'Diluar Geofence',
          value: {
            isOutsideLocation: true,
            geofenceDistance: { $ne: null },
          },
        },
        {
          title: 'Absensi Manual',
          value: {
            isManual: true,
          },
        },
        // {
        //   title: this.$status.proses,
        //   value: {
        //     //absensiCompleted: true,
        //     $or: [{ supervisorApproval: { $exists: false } }, { supervisorApproval: '' }],
        //   },
        // },
        {
          title: this.$status.belum,
          value: {
            //absensiCompleted: false,
            $or: [{ supervisorApproval: { $exists: false } }, { supervisorApproval: '' }],
          },
        },
        {
          title: this.$status.approve,
          value: {
            absensiCompleted: true,
            supervisorApproval: this.$status.approve,
          },
        },
        {
          title: this.$status.reject,
          value: {
            absensiCompleted: true,
            supervisorApproval: this.$status.reject,
          },
        },
        {
          title: this.$status.semua,
          value: {
            absensiCompleted: undefined,
            supervisorApproval: undefined,
          },
        },
        {
          title: 'Memiliki Shift',
          value: {
            shiftId: { $exists: true },
          },
        },
      ],
      queryFilter: null,
      queryFilters: [
        {
          title: 'Nama Pekerja',
          value: (term) => ({
            '"worker.fullName"': {
              $regex: term || '',
              $options: 'i',
            },
          }),
        },
        {
          title: 'Email Pekerja',
          value: (term) => ({
            'worker.email': {
              $regex: term || '',
              $options: 'i',
            },
          }),
        },

        {
          title: 'Nomor ID Pekerja',
          value: (term) => ({
            'worker.idNumber': {
              $regex: term || '',
              $options: 'i',
            },
          }),
        },
      ],
      clients: [],
      clientId: '',
      clientLoading: false,
      areas: {
        items: null,
        value: null,
      },
      locations: {
        items: null,
        value: null,
      },
      shifts: [{ _id: this.$status.semua, title: this.$status.semua }],
      // shifts: [{ _id: this.$status.semua, title: this.$status.semua },{ _id: this.$status.semua, title: this.$status.semua }],
      shiftId: this.$status.semua,
      shiftLoading: false,
      from: {
        modal: false,
        value: this.$moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
      },
      to: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
    }
  },
  computed: {
    getAttend() {
      return this.$store.state.attendance?.data || []
    },
    getAreaItems() {
      return this.areas.items ? [this.$status.semua, ...this.areas.items.map((x) => x.title)] : []
    },
    getAreaName: {
      set(value) {
        if (value === this.$status.semua) {
          this.areas.value = {
            ...this.areas.items[0],
            title: this.$status.semua,
          }
          this.locations.items = this.areas.items.flatMap((x) => x.locations)
        } else {
          this.areas.value = this.areas.items ? this.areas.items.find((x) => x.title === value) : null
          this.locations.items = this.areas.value ? this.areas.value.locations : null
        }
        this.getLocName = this.$status.semua
        this.getShifts()
      },
      get() {
        return this.areas.value ? this.areas.value.title : ''
      },
    },
    getLocItems() {
      return this.locations.items ? [this.$status.semua, ...this.locations.items.map((x) => x.name)] : []
    },
    getLocName: {
      set(value) {
        if (value === this.$status.semua) {
          this.locations.value = {
            ...this.areas.items[0],
            title: this.$status.semua,
          }
        } else {
          this.locations.value = this.locations.items ? this.locations.items.find((e) => e.name === value) : null
        }
        this.getShifts()
      },
      get() {
        return this.locations.value ? this.locations.value.title || this.locations.value.name : ''
      },
    },
    formatFromDate: {
      set(value) {
        this.from.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.from.value).format('DD MMMM YYYY')
      },
    },
    formatToDate: {
      set(value) {
        this.to.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.to.value).format('DD MMMM YYYY')
      },
    },
  },
  watch: {
    clientId: {
      async handler(value) {
        await this.$store.dispatch('area/getAll', {
          query: {
            clientId: value,
          },
        })
        this.areas.items = this.$store.state.area?.data || []
        this.getShifts()
      },
      deep: true,
    },
    comment() {
      if (!this.comment) {
        this.comment = 'OK'
      }
    },
    page: {
      handler() {
        this.loadData()
      },
      deep: true,
    },
  },
  async mounted() {
    return
    this.sort = this.constant[0]
    await this.reset()
    this.$showDialog({
      title: 'Informasi Absensi',
      body: 'Approval Absensi hanya bisa dilakukan jika sudah melalukan filtering dengan memilih Area dan Lokasi',
    })
  },
  methods: {
    async getClients(elem) {
      this.clientLoading = true
      const value = elem.target.value
      const result = await this.$api
        .get('/Clients/getClients', {
          params: {
            jsonQuery: JSON.stringify({
              name: { $regex: value || '', $options: 'i' },
            }),
          },
        })
        .then((data) => data?.result || [])
      this.clients = result
      this.clientLoading = false
    },
    async getShifts() {
      const clientId = this.clientId
      const area = this.areas.value
      const location = this.locations.value
      if (!clientId) {
        return
      }
      this.shiftLoading = true
      const jsonQuery = {}
      if (clientId) {
        jsonQuery.clientId = clientId
      }
      if (area?._id && area.title !== this.$status.semua) {
        jsonQuery.areaId = area._id
      }
      if (location?._id && location.title !== this.$status.semua) {
        jsonQuery.locationId = location._id
      }
      jsonQuery.pipeline = [
        {
          $sort: {
            startDate: -1,
          },
        },
      ]
      const result = await this.$api
        .get('/QuotationItems/getShifts', {
          params: {
            jsonQuery: JSON.stringify(jsonQuery),
          },
        })
        .then((data) => data?.result || [])
      this.shifts = [{ _id: this.$status.semua, title: this.$status.semua }].concat(result)
      this.shiftLoading = false
    },
    async reset() {
      this.$store.commit('SET', { loading: true })
      try {
        await this.$store.dispatch('client/getAll', { limit: 0 })
        this.clients = this.$store.state.client?.data
        this.referrals = await this.$api.get(`/WorkerReferrals/get`).then((data) => data?.result || [])
        this.areas.items = []
        this.getAreaName = this.$status.semua
        this.page = 1
        this.day = this.$days[0]
        this.filter = [this.$status.semua]
        this.getSummary()
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async loadData() {
      this.getSummary()
      this.getAttendance()
    },
    async approveBulk() {
      const arrAbsensi = this.$store.state.attendance?.data
        .filter((x) => x?.locationId && x?.absensiCompleted)
        .map((item) => {
          return {
            _id: item._id,
            supervisorId: this.$store.state.user?._id,
            supervisorName: this.$store.state.user?.nickname,
            supervisorApproval: 'Approved',
            supervisorComment: 'OK - Approval',
            supervisorTimeStamp: new Date(),
          }
        })
      if (arrAbsensi.length > 0) {
        await this.$store.dispatch('attendance/approveBulk', arrAbsensi)
        this.getAttendance()
      } else {
        this.$showDialog({
          title: 'Kesalahan',
          body: 'Tidak ada pekerja yang selesai absensi!',
        })
      }
    },
    async toggleApproval() {
      this.$store.commit('SET', { loading: true })
      try {
        const data = {
          ...this.showApprove,
          supervisorId: this.$store.state.user?._id,
          supervisorName: this.$store.state.user?.nickname,
          supervisorComment: this.comment
            ? this.comment
            : 'OK - ' + this.showApprove?.supervisorApproval == this.$status.approve
            ? 'Approve'
            : 'Reject',
          supervisorTimeStamp: new Date(),
          worker: undefined,
          detail: undefined,
          client: undefined,
        }
        await this.$store
          .dispatch('attendance/setStatus', data)
          .then(() => {
            this.$store.commit('dialog/show', {
              title: 'Berhasil',
              body: `Pekerja atas nama ${this.$toCapitalize(this.showApprove?.worker?.fullName || '-')} berhasil ${
                (this.showApprove?.supervisorApproval !== this.$status.approve && 'reject') || ''
              } approval`,
            })
          })
          .catch(() => {
            this.$store.commit('dialog/show', {
              title: 'Gagal',
              body: `Pekerja atas nama ${this.$toCapitalize(this.showApprove?.worker?.fullName || '-')} gagal ${
                (this.showApprove?.supervisorApproval !== this.$status.approve && 'reject') || ''
              } approval`,
            })
          })
      } finally {
        this.showDetail = null
        this.showApprove = null
        this.comment = ''
        this.loadData()
        this.$store.commit('SET', { loading: false })
      }
    },
    async suspendWorker(reason) {
      if (!this.showSuspend) return Promise.reject('Kesalahan Data Pekerja')
      this.$store.commit('SET_LOADING', true)
      await this.$store
        .dispatch('worker/suspend', {
          ...this.showSuspend,
          reason,
        })
        .then((result) => {
          this.loadData()
          this.$showDialog({
            title: 'Berhasil',
            body: `${this.showSuspend?.fullName || 'Pekerja'} telah disuspend.`,
          })
          this.showSuspend = null
        })

      this.$store.commit('SET_LOADING', false)
    },
    async unSuspendWorker(data) {
      if (!data) return Promise.reject('Kesalahan Data Pekerja')
      this.$store.commit('SET', { loading: true })
      await this.$store
        .dispatch('worker/unsuspend', {
          ...data,
        })
        .then((result) => {
          this.loadData()
          this.$showDialog({
            title: 'Berhasil',
            body: `${data?.fullName || 'Pekerja'} telah diunsuspend.`,
          })
        })
      this.$store.commit('SET', { loading: false })
    },
    async getSummary() {
      this.reportLoading = true
      await this.$store.dispatch('attendance/getSummary', {
        ...this.queries(),
      })
      this.reportLoading = false
    },
    async getAttendance() {
      this.mainLoading = true
      const workerFilter = this.getWorkerFilter()
      // console.log({
      //   ...this.queries(),
      //   ...(this.filter && this.filter.length > 0
      //     ? Object.assign({}, ...this.filter?.map((x) => this.filters?.find((y) => y.title === x)?.value))
      //     : []),
      //   $or: [
      //     ...(this.queryFilter?.length > 0
      //       ? this.queryFilter?.map((x) => this.queryFilters?.find((y) => y.title === x.title)?.value?.(this.term))
      //       : this.queryFilters.map((x) => x?.value?.(this.term))),
      //   ],
      // })
      await this.$store.dispatch('attendance/getAll', {
        custom: {
          pipeline: [
            {
              $sort: {
                phlJamMulai: this.sort === this.constant[0] ? -1 : 1,
              },
            },
            {
              $lookup: {
                from: 'Workers',
                as: 'worker',
                localField: 'ktpPhl',
                foreignField: '_id',
              },
            },
            {
              $unwind: {
                path: '$worker',
                preserveNullAndEmptyArrays: true,
              },
            },
            {
              $match: {
                ...this.queries(),
                ...(this.filter && this.filter.length > 0
                  ? Object.assign({}, ...this.filter?.map((x) => this.filters?.find((y) => y.title === x)?.value))
                  : []),
                $or: [
                  ...(this.queryFilter?.length > 0
                    ? this.queryFilter?.map((x) =>
                        this.queryFilters?.find((y) => y.title === x.title)?.value?.(this.term),
                      )
                    : this.queryFilters.map((x) => x?.value?.(this.term))),
                ],
              },
            },
            {
              $lookup: {
                from: 'QuotationItems',
                localField: 'shiftId',
                foreignField: '_id',
                as: 'shift',
              },
            },
            {
              $unwind: {
                path: '$shift',
                preserveNullAndEmptyArrays: true,
              },
            },
            {
              $lookup: {
                from: 'Clients',
                localField: 'clientId',
                foreignField: '_id',
                as: 'client',
              },
            },
            {
              $unwind: {
                path: '$client',
                preserveNullAndEmptyArrays: true,
              },
            },
            // {
            //   $unwind: {
            //     path: '$detail',
            //     preserveNullAndEmptyArrays: true,
            //   },
            // },
            {
              $lookup: {
                from: 'Areas',
                localField: 'areaId',
                foreignField: '_id',
                as: 'area',
              },
            },
            {
              $unwind: {
                path: '$area',
                preserveNullAndEmptyArrays: true,
              },
            },
          ],
        },
        day: this.day,
        page: this.page,
      })
      this.mainLoading = false
    },
    getXlsxFilter() {
      const workerFilter = this.getWorkerFilter()
      const term = { $regex: this.term || '', $options: 'i' }
      return {
        ...this.queries(),
        ...(this.filter && this.filter.length > 0
          ? Object.assign({}, ...this.filter?.map((x) => this.filters?.find((y) => y.title === x)?.value))
          : []),
        pipeline: [
          { $sort: { phlJamMulai: -1 } },
          {
            $lookup: {
              from: 'Workers',
              localField: 'ktpPhl',
              foreignField: '_id',
              as: 'worker',
            },
          },
          {
            $unwind: {
              path: '$worker',
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $lookup: {
              from: 'WorkerReferrals',
              localField: 'worker.workerReferral',
              foreignField: '_id',
              as: 'worker.workerReferral',
            },
          },
          {
            $unwind: {
              path: '$worker.workerReferral',
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $match: workerFilter,
          },
          {
            $lookup: {
              from: 'Clients',
              localField: 'clientId',
              foreignField: '_id',
              as: 'client',
            },
          },
          {
            $unwind: {
              path: '$client',
              preserveNullAndEmptyArrays: true,
            },
          },

          // {
          //   $unwind: {
          //     path: '$detail',
          //     preserveNullAndEmptyArrays: true,
          //   },
          // },
          {
            $lookup: {
              from: 'Areas',
              localField: 'areaId',
              foreignField: '_id',
              as: 'area',
            },
          },
          {
            $unwind: {
              path: '$area',
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $match: {
              $or: [{ 'worker.fullName': term }, { 'worker.email': term }, { 'worker.email': term }],
            },
          },
        ],
      }
    },
    async exportXlsx() {
      this.$store.commit('SET', { loading: true })
      const jsonQuery = this.getXlsxFilter()
      // handle xlsx
      const realm = await this.$realm()
      const attendances = await realm.functions.getAttendances(JSON.stringify(jsonQuery))
      const columns = [
        {
          label: 'No KTP',
          value: 'No KTP',
        },
        {
          label: 'Nama Pekerja',
          value: 'Nama Pekerja',
        },
        {
          label: 'Perusahaan',
          value: 'Perusahaan',
        },
        {
          label: 'No Telepon',
          value: 'No Telepon',
        },
        {
          label: 'Kapten',
          value: 'Kapten',
        },
        {
          label: 'Area',
          value: 'Area',
        },
        {
          label: 'Gudang/Lokasi',
          value: 'Gudang/Lokasi',
        },
        {
          label: 'Shift',
          value: 'Shift',
        },
        {
          label: 'Hari',
          value: 'Hari',
        },
        {
          label: 'Tanggal Mulai',
          value: 'Tanggal Mulai',
        },
        {
          label: 'Jam Mulai',
          value: 'Jam Mulai',
        },
        {
          label: 'Tanggal Selesai',
          value: 'Tanggal Selesai',
        },
        {
          label: 'Jam Selesai',
          value: 'Jam Selesai',
        },
        {
          label: 'Lokasi Mulai',
          value: 'Lokasi Mulai',
        },
        {
          label: 'Lokasi Selesai',
          value: 'Lokasi Selesai',
        },
        {
          label: 'Note Mulai',
          value: 'Note Mulai',
        },
        {
          label: 'Note Selesai',
          value: 'Note Selesai',
        },
        {
          label: 'Supervisor Name',
          value: 'Supervisor Name',
        },
        {
          label: 'Supervisor Approval',
          value: 'Supervisor Approval',
        },
        {
          label: 'Jenis Rekening',
          value: 'Jenis Rekening',
        },
        {
          label: 'Nama Rekening',
          value: 'Nama Rekening',
        },
        {
          label: 'Nomor Rekening',
          value: 'Nomor Rekening',
        },
        {
          label: 'Sudah Bayar Admin',
          value: 'Sudah Bayar Admin',
        },
        {
          label: 'Referral',
          value: 'Referral',
        },
        {
          label: 'Absensi Manual',
          value: 'Absensi Manual',
        },
      ]
      const content = attendances
        .sort((a, b) => b.phlJamMulai - a.phlJamMulai)
        .map((item) => ({
          'No KTP': item['No KTP'],
          'Nama Pekerja': item['Nama Pekerja'],
          Perusahaan: item['Perusahaan'],
          "No Telepon": item['No Telepon'],
          Kapten: item['Kapten'],
          Area: item['Area'],
          'Gudang/Lokasi': item['Gudang/Lokasi'],
          Shift: item['Shift'],
          Hari: item['Hari'],
          'Tanggal Mulai': item['Tanggal Mulai'],
          'Jam Mulai': item['Jam Mulai'],
          'Tanggal Selesai': item['Tanggal Selesai'],
          'Jam Selesai': item['Jam Selesai'],
          'Lokasi Mulai': item['Lokasi Mulai'],
          'Lokasi Selesai': item['Lokasi Selesai'],
          'Note Mulai': item['Note Mulai'],
          'Note Selesai': item['Note Selesai'],
          'Supervisor Name': item['Supervisor Name'],
          'Supervisor Approval': item['Supervisor Approval'],
          'Jenis Rekening': item['Jenis Rekening'],
          'Nama Rekening': item['Nama Rekening'],
          'Nomor Rekening': item['Nomor Rekening'],
          'Sudah Bayar Admin': item['Sudah Bayar Admin'],
          Referral: item['Referral'],
          'Absensi Manual': item['Absensi Manual'],
        }))
      let data = [
        {
          sheet: 'Absensi',
          columns,
          content,
        },
      ]
      let settings = {
        fileName: 'Absensi',
      }
      this.$xlsx(data, settings)
      this.$store.commit('SET', { loading: false })
    },
    async exportXlsxByDays() {
      this.$store.commit('SET', { loading: true })
      const jsonQuery = this.getXlsxFilter()
      // handle xlsx
      const realm = await this.$realm()
      let items = await realm.functions.getAttendances(JSON.stringify(jsonQuery))
      //* Example Data
      // let items = [
      //   {
      //     phlJamMulai: "2022-10-18T11:10:16.846Z",
      //     "No KTP": "3273290307970003",
      //     "Nama Pekerja": "indra lubis",
      //     Perusahaan: "PT Gudang Segar Indonesia",
      //     Kapten: "",
      //     Area: "BDG",
      //     "Gudang/Lokasi": "Gudang Warpin Bandung reg",
      //     Hari: "Selasa",
      //     "Tanggal Mulai": "18-10-2022",
      //     "Jam Mulai": "18:10",
      //     "Tanggal Selesai": null,
      //     "Jam Selesai": null,
      //     "Lokasi Mulai":
      //       "Jl. Raya Sapan No.69, Tegalluar, Kec. Bojongsoang, Kabupaten Bandung, Jawa Barat 40287, Indonesia",
      //     "Note Mulai": "",
      //     "Supervisor Name": "",
      //     "Supervisor Approval": "",
      //     "Jenis Rekening": "BCA",
      //     "Nama Rekening": "indra lubis",
      //     "Nomor Rekening": "1234567890",
      //     "Sudah Bayar Admin": "Sudah",
      //     Referral: "HK001-HOJakarta",
      //   },
      // ]
      // ==== petunjuk ====
      // bikin array of array
      // spreading data per array
      // bikin variable acuan untuk data sebelum data utama (anggap $prologLength)
      // array pertama untuk spreading tanggal, kosongin $prologLength di awal
      // array kedua untuk spreading label, kosongin $prologLength di awal
      // array ketiga isi nama dengan jam absen orang tersebut
      // ==================

      // distinct by idNumber
      const obj = items.reduce((total, item) => {
        return {
          ...total,
          [item['No KTP']]: [...(total?.[item['No KTP']] || []), item],
        }
      }, {})
      items = Object.values(obj)
        .flatMap((item) => item)
        .sort((a, b) => a['Nama Pekerja'].localeCompare(b['Nama Pekerja']))
      const listWorkers = Array.from(new Set(items.map((item) => item['No KTP']))).map((idNumber) => {
        return obj[idNumber][0]
      })
      console.log('listWorkers', listWorkers)
      let newItems = {}
      items.forEach((item) => {
        const pointerDateStart = 'Tanggal Mulai ->' + item['Tanggal Mulai']
        const pointerDateEnd = 'Tanggal Selesai ->' + item['Tanggal Selesai']
        const clockIn = {
          idNumber: item['No KTP'],
          clock: item?.['Jam Mulai'] || '',
        }
        const clockOut = {
          idNumber: item['No KTP'],
          clock: item?.['Jam Selesai'] || '',
        }
        if (Object.keys(newItems).includes(pointerDateStart)) {
          newItems[pointerDateStart] = [...(newItems?.[pointerDateStart] || []), clockIn]
        } else {
          newItems = {
            ...newItems,
            [pointerDateStart]: [clockIn],
          }
        }
        if (Object.keys(newItems).includes(pointerDateEnd)) {
          newItems[pointerDateEnd] = [...(newItems?.[pointerDateEnd] || []), clockOut]
        } else {
          newItems = {
            ...newItems,
            [pointerDateEnd]: [clockOut],
          }
        }
      })
      console.log('newItems', newItems)
      const arrItems = Object.entries(newItems)
        .map((items) => [items[0].split('->')[1], items[1]])
        .sort((a, b) => (a[0] > b[0] ? 1 : b[0] > a[0] ? -1 : 0))
      const dates = arrItems.map((items) => items[0])
      const clocks = arrItems.map((items) => items[1])
      console.log('clocks', clocks)
      // execution
      const prologLength = 1
      // setup first Array
      let firstArray = []
      dates.forEach((item) => {
        firstArray.push(item)
      })
      firstArray = new Array(prologLength).fill('').concat(firstArray)
      // setup second Array
      let secondArray = []
      items.forEach(() => {
        secondArray.push('Jam Masuk')
        secondArray.push('Jam Keluar')
      })
      secondArray = new Array(prologLength).fill('').concat(secondArray)
      // setup bulk Array
      let bulkArray = []
      let childArray = []
      clocks.forEach((_, clockIdx) => {
        listWorkers.forEach((workerProfile, idx) => {
          const workerName = workerProfile?.['Nama Pekerja'] || ''
          const idNumber = workerProfile?.['No KTP'] || ''
          const clockProfile = clocks?.[clockIdx]?.[idx] || {}
          // handle child array
          if (clockIdx === 0) {
            childArray.push(workerName)
          }
          if (clockProfile?.idNumber == idNumber) {
            childArray.push(clockProfile?.clock || '')
          }
          // handle bulk array
          if (clockIdx === 0) {
            bulkArray.push(childArray)
          } else {
            bulkArray[idx] = bulkArray[idx].concat(childArray)
          }
          childArray = []
        })
      })
      console.log(bulkArray)
      const content = [
        Object.assign({}, firstArray),
        Object.assign({}, secondArray),
        ...bulkArray.map((arr) => Object.assign({}, arr)),
      ]
      const columns = Object.keys(content?.[0] || []).map((key, idx) => {
        return {
          label: idx + 1,
          value: key,
        }
      })
      let data = [
        {
          sheet: 'Absensi',
          columns,
          content,
        },
      ]
      let settings = {
        fileName: 'Absensi By Days',
      }
      this.$xlsx(data, settings)
      this.$store.commit('SET', { loading: false })
    },
    getWorkerFilter() {
      const term = { $regex: this.term || '', $options: 'i' }
      const workerFilter = {
        $or: [{ 'worker.fullName': term }, { 'worker.email': term }, { 'worker.idNumber': term }],
      }
      if (this.referralId) {
        workerFilter.$or = { 'worker.workerReferral': this.referralId }
      }
      return workerFilter
    },
    queries() {
      const query = {
        phlJamMulai: {
          $gte: new Date(this.$moment(this.from.value).toDate().setHours(0, 0, 0, 0)),
          $lte: new Date(this.$moment(this.to.value).toDate().setHours(24, 0, 0, 0)),
        },
        shiftId: this.$route?.query?.shift,
        clientId: this.clientId ? this.clientId : { $exists: true },
        areaId:
          this.areas.value && this.areas.value.title === this.$status.semua ? { $exists: true } : this.areas.value?._id,
        locationId:
          this.locations.value && this.locations.value.title === this.$status.semua
            ? { $exists: true }
            : this.locations.value?._id,
      }
      const shiftId = this.shiftId
      if (shiftId && shiftId !== this.$status.semua) {
        query.shiftId = shiftId
      }
      return query
    },
  },
}
</script>
